export function isEmpty(str: string) {
    return !str || str.length === 0 || str === "" || str === " ";
}

export const jsonParseString = (val: string) => {
    try {
        return JSON.parse(val) as Record<string, any>;
    } catch (error) {
        return null;
    }
};

export const jsonParseStringArray = (val: string) => {
    try {
        const parsed = JSON.parse(val);
        if (!Array.isArray(parsed)) {
            return null;
        }
        return parsed;
    } catch (error) {
        return null;
    }
}

export function extractScCurrValueFromProductAttr(data: any, salesChannelId: string, key: string) {
    let newData;
    try {
        let extractedScData = (JSON.parse(data ?? "[]") ?? []).find(
            (el: { salesChannelId: string }) => {
                return el.salesChannelId === salesChannelId;
            }
        );

        if (!extractedScData) {
            extractedScData = (JSON.parse(data ?? "[]") ?? []).find(
                (el: { salesChannelId: string }) => {
                    return el.salesChannelId === "global";
                }
            );
        }

        newData = extractedScData?.[key] ?? data;
    } catch (error) {
        newData = data;
    }
    return newData;
}
