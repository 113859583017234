import { ADDRESS_FIELDS_FRAGMENT, ORDER_FIELDS_FRAGMENT } from "./fragments";

export const ADD_TO_CART = /* GraphQL */ `
    ${ORDER_FIELDS_FRAGMENT}
    mutation AddToCart($data: AddToCartInput!) {
        addToCart(data: $data) {
            ...OrderFields
        }
    }
`;

export const RE_ORDER = /* GraphQL */ `
    ${ORDER_FIELDS_FRAGMENT}
    mutation ReOrder($data: [AddToCartInput]) {
        reOrder(data: $data) {
            ...OrderFields
        }
    }
`;

export const ADD_CUSTOMER_ORDER = /* GraphQL */ `
    ${ORDER_FIELDS_FRAGMENT}
    mutation AddCustomerOrder($data: AddCustomerOrderInput!) {
        addCustomerOrder(data: $data) {
            ...OrderFields
        }
    }
`;

export const EDIT_ORDER_ITEM_IN_CART = /* GraphQL */ `
    ${ORDER_FIELDS_FRAGMENT}
    mutation EditOrderItem($data: EditOrderItemInput!) {
        editOrderItem(data: $data) {
            ...OrderFields
        }
    }
`;

export const REMOVE_ORDER_ITEM = /* GraphQL */ `
    ${ORDER_FIELDS_FRAGMENT}
    mutation RemoveOrderItem($data: RemoveOrderItemInput!) {
        removeOrderItem(data: $data) {
            ...OrderFields
        }
    }
`;
export const UPDATE_ORDER = /* GraphQL */ `
    ${ORDER_FIELDS_FRAGMENT}
    mutation UpdateOrder($id: String!, $data: OrderUpdateInput!) {
        updateOrder(id: $id, data: $data) {
            ...OrderFields
        }
    }
`;
export const UPDATE_SHIPPING_COSTS = /* GraphQL */ `
    ${ORDER_FIELDS_FRAGMENT}
    mutation AddEditOrderAddress($data: updateShippingCostsInput!) {
        updateShippingCosts(data: $data) {
            ...OrderFields
        }
    }
`;
export const PLACE_ORDER = /* GraphQL */ `
    ${ORDER_FIELDS_FRAGMENT}
    mutation PlaceOrder($data: PlaceOrderInput!) {
        placeOrder(data: $data) {
            ...OrderFields
        }
    }
`;
export const CHECK_ORDER_CAN_BE_PLACED = /* GraphQL */ `
    ${ORDER_FIELDS_FRAGMENT}
    mutation CheckOrderCanBePlaced($data: PlaceOrderInput!) {
        checkOrderCanBePlaced(data: $data) {
            ...OrderFields
        }
    }
`;

export const CREATE_ORDER_FROM_CART_LINK = /* GraphQL */ `
    ${ORDER_FIELDS_FRAGMENT}
    mutation CreateOrderFromCartLink($data: CreateOrderFromCartLinkInput) {
        createOrderFromCartLink(data: $data) {
            ...OrderFields
        }
    }
`;

export const ADD_EDIT_ORDER_ADDRESS = /* GraphQL */ `
    ${ORDER_FIELDS_FRAGMENT}
    mutation AddEditOrderAddress($data: AddEditAddressInput) {
        addEditOrderAddress(data: $data) {
            ...OrderFields
        }
    }
`;

export const ADD_EDIT_ORDER_NOTES = /* GraphQL */ `
    ${ORDER_FIELDS_FRAGMENT}
    mutation AddEditOrderNotes($data: AddEditOrderNotesInput) {
        addEditOrderNotes(data: $data) {
            ...OrderFields
        }
    }
`;
export const CREATE_ADDRESS = `
  ${ADDRESS_FIELDS_FRAGMENT}
  mutation CreateAddress($input: CreateAddressInput!) {
      createAddress(input: $input) {
        ...AddressFields
      }

  }
`;

export const SELECT_SHIPPING_METHOD_FOR_SHIPMENT = /* GraphQL */ `
    ${ORDER_FIELDS_FRAGMENT}
    mutation SelectShippingMethodForShipment($data: SelectShippingMethodForShipmentInput!) {
        selectShippingMethodForShipment(data: $data) {
            ...OrderFields
        }
    }
`;

export const UPDATE_DISCOUNT_AMOUNT = /* GraphQL */ `
    ${ORDER_FIELDS_FRAGMENT}
    mutation UpdateDiscountAmount($data: updateDiscountAmountInput!) {
        updateDiscountAmount(data: $data) {
            ...OrderFields
        }
    }
`;
export const CREATE_CUSTOMER = /* GraphQL */ `
    mutation CreateCustomer($input: CreateCustomerInput!) {
        createCustomer(input: $input) {
            id
            firstName
            lastName
            email
            phoneNumber
            cognitoUser
        }
    }
`;
export const UPDATE_CUSTOMER = /* GraphQL */ `
    mutation UpdateCustomer($input: UpdateCustomerInput!) {
        updateCustomer(input: $input) {
            id
            firstName
            lastName
            email
            phoneNumber
        }
    }
`;

export const UPDATE_ADDRESS = `
  ${ADDRESS_FIELDS_FRAGMENT}
  mutation UpdateAddress( $input: UpdateAddressInput!) {
      updateAddress( input: $input) {
        ...AddressFields
      }

  }
`;
export const DELETE_ADDRESS = `
  ${ADDRESS_FIELDS_FRAGMENT}
  mutation DeleteAddress( $id: String!, $addressType: String, $customerId: String, $warehouseId: String, $salesChannel: String
    $tenant: String) {
      deleteAddress( id: $id, addressType: $addressType, customerId: $customerId, warehouseId: $warehouseId, salesChannel: $salesChannel,
    tenant: $tenant) {
        ...AddressFields
      }

  }
`;
