import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { CountryType, OrderType } from "../../types";
import EcommerceApi from "../../services/ecommerceApi";

const NAME = "country";

export const fetchAllCountries = createAsyncThunk(NAME + "/getCountries", async () => {
    const data = await EcommerceApi.getCountries();
    return data;
});

const initialState: {
    countries: CountryType[];
} = {
    countries: []
};
type countryStateType = typeof initialState;
const orderSlice = createSlice({
    name: "order",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchAllCountries.fulfilled, (state: countryStateType, action) => {
            state.countries = action.payload;
        });
    }
});

export default orderSlice.reducer;
