import React from "react";

import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import store from "./store";
import { EcommerceProvider } from "./context/ecommerce";
import { TrackingProvider } from "./context/tracking";
import { PageContext, PageProvider } from "./components/pages/context";

const Wrapper = ({ element }) => {
    const persistor = persistStore(store);
    return (
        <Provider store={store}>
            <EcommerceProvider>
                <TrackingProvider>
                    <PageProvider>
                        <PersistGate loading={null} persistor={persistor}>
                            {element}
                        </PersistGate>
                    </PageProvider>
                </TrackingProvider>
            </EcommerceProvider>
        </Provider>
    );
};
export default Wrapper;
