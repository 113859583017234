import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import AsyncStorage from "@react-native-async-storage/async-storage";

import order from "./order";
import country from "./country";
import auth from "./auth";
import currencyCode from "./currencyCode";

const persistConfig = {
    key: "store-site",
    storage: AsyncStorage,
    whitelist: ["order"],
    stateReconciler: autoMergeLevel2
};

const rootReducer = combineReducers({
    order,
    country,
    auth,
    currencyCode
});

const pReducer = persistReducer<any, any>(persistConfig, rootReducer) as typeof rootReducer;
const store = configureStore({
    reducer: pReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false
        })
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export default store;
