// ** Redux Imports
import { Dispatch } from "redux";
import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";

import EcommerceApi from "../../services/ecommerceApi";
import { CurrencyCodeType } from "../../types";

const NAME = "currencyCode";

export const fetchAllCurrencyCodes = createAsyncThunk(NAME + "/fetchAllCurrencyCode", async () => {
    const data = await EcommerceApi.getCurrencyCodes();
    return data;
});

const initialState: {
    selectedCurrencyCode: null | CurrencyCodeType;
} = {
    selectedCurrencyCode: null
};

export const currencyCodeBuilderSlice = createSlice({
    name: NAME,
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchAllCurrencyCodes.fulfilled, (state: any, action) => {
            state.selectedCurrencyCode = Array.isArray(action?.payload)
                ? action.payload[0]
                : action.payload;
        });
    }
});
export default currencyCodeBuilderSlice.reducer;
