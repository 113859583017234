import _ from "lodash";

import React, { useContext } from "react";

import { useMemo, useState, useEffect, useCallback, createContext } from "react";
import { ContentType, ModelType, ProductCategoryType, ProductType } from "../../types";
import { graphql, useStaticQuery } from "gatsby";
import { jsonParseString } from "../../utils/string";
import { ModelContentParsed } from "../content/CMSContentItem";

type PageContextType = {
    contentDict: _.Dictionary<ModelContentParsed>;
    productCategories: Pick<ProductCategoryType, "id" | "name" | "images">[];
    productDetailProduct: ProductType | undefined;
    setProductDetailProduct: React.Dispatch<React.SetStateAction<ProductType | undefined>>;
};

// ============== CONTEXT ==============
export const PageContext = createContext({} as PageContextType);

// ============== PROVIDER ==============
type Props = {
    children: React.ReactNode;
};
export function PageProvider({ children }: Props) {

    const { allContent, allProductCategory } = useStaticQuery(graphql`
        query {
            allContent {
                nodes{
                id
                modelId
                data
                imageFile{
                    publicURL
                    childImageSharp {
                        gatsbyImageData(
                            placeholder: BLURRED
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                }
                }
            }
            allProductCategory {
                nodes {
                    id
                    name
                    images {
                        id
                        url
                        name
                        title
                        altTag
                        sort
                        imageFile {
                            publicURL
                            childImageSharp {
                                gatsbyImageData(
                                    placeholder: BLURRED
                                    formats: [AUTO, WEBP, AVIF]
                                )
                            }
                        }
                    }
                }
            }
        }
    `);

    // Product Categories
    const productCategories = allProductCategory.nodes as Pick<ProductCategoryType, "id" | "name" | "images">[];
    const [productDetailProduct, setProductDetailProduct] = useState<ProductType | undefined>(undefined);

    const contentItems = allContent.nodes as ContentType[];

    const modelContent = contentItems
        .map(content => ({
            ...content,
            ...jsonParseString(content.data)
        })) as ModelContentParsed[];

    const contentDict: _.Dictionary<ModelContentParsed> = _.keyBy(modelContent, "id");

    const memoizedValue = useMemo(
        () =>
        ({
            productCategories,
            productDetailProduct,
            setProductDetailProduct,
            contentDict
        } as PageContextType),
        [
            productDetailProduct,
            setProductDetailProduct,
            contentDict
        ]
    );

    return <PageContext.Provider value={memoizedValue}>{children}</PageContext.Provider>;
}

// ============== HOOK ==============
export const usePageContext = () => {
    const context = useContext(PageContext);
    if (!context) throw new Error("usePageContext context must be use inside PageProvider");
    return context;
};
