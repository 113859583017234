import { Amplify, Auth } from "aws-amplify";
import { CognitoUser } from "amazon-cognito-identity-js";

import { getSiteKeys } from "../../utils/config";
import EcommerceApi from "../../services/ecommerceApi";
import { CustomerType } from "../../types";
import { ISalesChannel } from "../ecommerceApi/SalesChannel/types";

let cognitoUser: CognitoUser | undefined = undefined;

Amplify.configure({
    Auth: {
        region: process.env.GATSBY_REGION,
        userPoolId: process.env.GATSBY_USER_POOL_ID,
        userPoolWebClientId: process.env.GATSBY_USER_POOL_WEB_CLIENT_ID
    }
});

const normalizeUser = user => {
    if (user) {
        for (const key in user.attributes) {
            const attr = user.attributes[key];
            user[key] = attr;
        }
    }
    return user;
};

// const signIn = async (username, password) => {
//     try {
//         const user = await Auth.signIn(username, password);
//         return user;
//     } catch (e) {
//         console.error("signIn", e);
//     }
//     return null;
// };

const signOut = async () => {
    try {
        return await Auth.signOut();
    } catch (e) {
        console.error("signOut", e);
    }
    return null;
};

const getCognitoUser = async () => {
    try {
        return normalizeUser(await Auth.currentAuthenticatedUser());
    } catch (e) {
        return null;
    }
};
function getRandomString(bytes: number) {
    const randomValues = new Uint8Array(bytes);
    window.crypto.getRandomValues(randomValues);
    return Array.from(randomValues).map(intToHex).join("");
}

function intToHex(nr: number) {
    return nr.toString(16).padStart(2, "0");
}
export async function signUp(
    email: string,
    firstName: string,
    lastName: string,
    phoneNumber: string,
    salesChannel: ISalesChannel
) {
    const { tenant, salesChannel: scid } = getSiteKeys();
    let customer;
    const customerLookup = await EcommerceApi.getCustomerByEmail(email);

    if (!customerLookup || customerLookup.length === 0) {
        let newCustomer = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            phoneNumber,
            salesChannel: scid,
            salesChannelName: salesChannel.name
        };
        customer = await EcommerceApi.createCustomer(newCustomer);
    } else {
        customer = customerLookup && customerLookup.length > 0 ? customerLookup[0] : null;
    }

    if (!customer) throw new Error("Customer not found");

    const params = {
        username: `${scid}-${customer?.id}`,
        password: `D3!g${getRandomString(30).toString()}`,
        attributes: {
            given_name: firstName,
            family_name: lastName,
            email: email,
            "custom:customer_id": customer?.id,
            "custom:tenant_id": tenant,
            "custom:sales_channel": scid
        }
    };
    return await Auth.signUp(params);
}

export async function signIn(email: string): Promise<{
    user: CognitoUser | undefined;
    customer: CustomerType | undefined;
    apiError: boolean;
}> {
    let apiError = false;

    const { salesChannel } = getSiteKeys();

    const customerLookup = await EcommerceApi.getCustomerByEmail(email);

    let customer =
        customerLookup && customerLookup != "error" && customerLookup.length > 0
            ? customerLookup[0]
            : undefined;

    apiError = customerLookup === "error";

    if (!customer) {
        console.log("Customer not found");
    } else {
        cognitoUser = undefined;
        try {
            cognitoUser = await Auth.signIn(`${salesChannel}-${customer.id}`);
        } catch (e) {
            console.log("Sign In Failed", e);
        }
    }

    return {
        user: cognitoUser,
        customer,
        apiError
    };
}

export async function answerCustomChallenge(answer: string) {
    // Send the answer to the User Pool
    // This will throw an error if it’s the 3rd wrong answer
    cognitoUser = await Auth.sendCustomChallengeAnswer(cognitoUser, answer);

    // It we get here, the answer was sent successfully,
    // but it might have been wrong (1st or 2nd time)
    // So we should test if the user is authenticated now
    try {
        // This will throw an error if the user is not yet authenticated:
        await Auth.currentSession();
        return true;
    } catch {
        return false;
    }
}

// get auth token
const getAuthToken = async () => {
    try {
        const session = await Auth.currentSession();

        return session.getIdToken().getJwtToken();
    } catch (e) {
        console.error("getAuthToken", e);
        return null;
    }
};

export default {
    signIn,
    signUp,
    signOut,
    getCognitoUser,
    getAuthToken
};
