export const getSiteKeys = () => {
    const GQL_ENDPOINT = process.env.GATSBY_APP_SYNC_GQL as string;
    const GQL_API_KEY = process.env.GATSBY_APP_SYNC_KEY as string;
    const cyclicKey = String(process.env.GATSBY_CYCLIC_KEY).toUpperCase();
    const keyPartitions = cyclicKey.split("-");
    const tenant = keyPartitions[0];
    const salesChannel = keyPartitions[1];
    return {
        GQL_ENDPOINT,
        GQL_API_KEY,
        tenant,
        salesChannel
    };
};

const EMPTY_TAG = "NO_CLIENT_ID_FOUND";
export const getClientID = () => {
    const dataLayer = window?.["dataLayer"];

    if (!dataLayer) {
        return EMPTY_TAG;
    }

    const formatedDataLayer = (dataLayer || []).map((event: any) => {
        if (event?.h?.event == "gtagApiGet") {
            return event.h;
        }
        return event;
    });

    let event = formatedDataLayer.find((event: any) => event?.event === "gtagApiGet");

    if (!event || !event?.gtagApiResult?.client_id) {
        return EMPTY_TAG;
    }

    return event?.gtagApiResult?.client_id;
};
