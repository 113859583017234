/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "./src/styles/global.scss";
import Wrapper from "./src/wrapper";
export const wrapPageElement = Wrapper;
